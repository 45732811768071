var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

function Rectangle(x, y, width, height) {
  if (!((this || _global) instanceof Rectangle)) {
    throw new Error("You cannot call this function without the \"new\" keyword");
  }

  ;
  (this || _global).x = x;
  (this || _global).y = y;
  (this || _global).width = width;
  (this || _global).height = height;
}

Rectangle.prototype.perimeter = function () {
  return (this || _global).width * 2 + (this || _global).height * 2;
};

Rectangle.prototype.area = function () {
  return (this || _global).width * (this || _global).height;
};

exports = Rectangle;
export default exports;